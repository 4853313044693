<template>
  <div :id="collection" class="flex flex-1 flex-col rounded border border-gray-800" :class="overflowOnFocus">
    <div
      class="group flex cursor-pointer flex-row items-center gap-2 rounded px-3 duration-200 hover:bg-blue-900/20"
      :class="[activeItem == collection ? 'bg-blue-900/20' : '', currentSearch ? 'pb-2 pt-3' : 'py-4']"
      @click="setSelectedCollection(selectedCollection ? null : collection)"
    >
      <template v-if="selectedCollection">
        <h3 class="text-gray-400">Global Search</h3>
        <ArrowRightIcon class="h-3 w-3 text-white" />
      </template>

      <h3 class="text-white">
        <slot />
      </h3>
      <div class="text-gray-400">({{ count.toLocaleString() }})</div>
      <div
        v-if="!!$slots.shortcut"
        class="rounded-sm bg-gray-800 px-1 font-mono tracking-wider duration-200 group-hover:bg-blue-900/50"
      >
        <slot name="shortcut" />
      </div>
      <ArrowRightIcon
        v-if="!selectedCollection"
        class="ml-auto h-4 w-4 text-gray-400 duration-200 group-hover:text-blue-600"
      />
    </div>
    <div v-if="currentSearch || collection == 'recent_searches'" class="flex flex-1 flex-col" :class="overflowOnFocus">
      <BaseTable
        :column-defs="columnDefs"
        class="flex-1"
        :dom-layout="selectedCollection ? '' : 'autoHeight'"
        :context="context"
        :get-row-id="params => params.data.id"
        row-selection="single"
        :row-height="rowHeight"
        @grid-ready="onGridReady"
        @body-scroll="onBodyScroll"
        @row-clicked="emits('select', `${collection}:${$event.data.id}`)"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, computed, ref, watch } from 'vue';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import { useGlobalSearchVisualization } from './global-search-visualization';

const selectedCollection = inject('selectedCollection');
const setSelectedCollection = inject('setSelectedCollection');
const overflowOnFocus = computed(() => (selectedCollection.value ? 'overflow-auto' : ''));

const currentSearch = inject('currentSearch');
const currentRefinement = inject('currentRefinement');
watch(
  () => currentSearch.value,
  () => {
    refinedNext.value = false;
    lastDisplayedRow.value = null;
    page.value = 1;
  }
);

const activeItem = inject('activeItem');
watch(activeItem, () => {
  const [collection, id] = activeItem.value.split(':');
  if (gridApi.value) {
    if (collection === props.collection) {
      const rowNode = gridApi.value.getRowNode(id);
      if (rowNode) {
        gridApi.value.ensureNodeVisible(rowNode);
        rowNode.setSelected(true);

        return;
      }
    }
    gridApi.value.deselectAll();
  }
});

const props = defineProps({
  hits: Array,
  collection: String,
  count: Number
});

const emits = defineEmits(['delete', 'refineNext', 'select']);

const { columnDefs, hitsTransform, rowHeight } = useGlobalSearchVisualization(props.collection);

const rowData = ref([]);
watch([() => props.hits, () => selectedCollection.value], setRowData, { deep: true });

function setRowData() {
  rowData.value = hitsTransform(props.hits.slice(0, !selectedCollection.value ? 3 : props.hits.length));
  if (gridApi.value) {
    gridApi.value.setRowData(rowData.value);

    if (refinedNext.value) {
      gridApi.value.ensureIndexVisible(lastDisplayedRow.value);
    }
    gridApi.value.sizeColumnsToFit();
  }
  refinedNext.value = false;
}

const gridApi = ref(null);
function onGridReady(params) {
  gridApi.value = params.api;
  setRowData();
}

const refinedNext = ref(false);
const page = ref(1);
const lastDisplayedRow = ref(null);
function onBodyScroll(event) {
  lastDisplayedRow.value = gridApi.value.getLastDisplayedRow();
  if (
    lastDisplayedRow.value > rowData.value.length - 10 &&
    selectedCollection.value != null &&
    !refinedNext.value &&
    props.count != props.hits.length
  ) {
    emits('refineNext', page.value + 1);
    refinedNext.value = true;
    page.value = page.value + 1;
  }
}

const context = {
  deleteItem: id => {
    emits('delete', id);
  },
  customHighlight: (text, buffer = 30) => {
    const highlightRegex = new RegExp(currentRefinement.value, 'gi');
    const matchPosition = text.search(highlightRegex);
    let startIndex = matchPosition - buffer;
    let endIndex = matchPosition + buffer;
    if (startIndex < 0) startIndex = 0;
    if (endIndex > text.length) endIndex = text.length;
    let truncatedText = text.substring(startIndex, endIndex);
    if (startIndex > 0) truncatedText = '...' + truncatedText;
    if (endIndex < text.length) truncatedText += '...';
    return truncatedText.replace(highlightRegex, match => `<span class="text-yellow-400">${match}</span>`);
  }
};
</script>
