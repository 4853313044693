import { computed } from 'vue';
import { useStore } from 'vuex';
import TableCellCoin from '@/components/table/renderer/TableCellCoin.vue';
import ScreenerPriceCell from '@/components/screener/ScreenerPriceCell.vue';
import TableRendererCompany from '@/components/table/renderer/TableRendererCompany.vue';
import RendererActions from '@/components/table/renderer/RendererActions.vue';
import TableCellNewsItem from '@/components/table/renderer/TableCellNewsItem.vue';

const highlightRenderer = {
  template: `<span v-html="params.context.customHighlight(params.value, 10000) || params.value" />`
};

export function useGlobalSearchVisualization(collection) {
  const $store = useStore();
  const coins = computed(() => $store.getters.coins);
  const columnDefsMap = {
    pages: [
      {
        headerName: 'Page',
        field: 'name'
      }
    ],
    coins: [
      {
        headerName: 'Coin',
        cellRenderer: TableCellCoin,
        field: 'coin'
      },
      {
        headerName: 'Tab',
        field: 'tab'
      },
      {
        headerName: 'Price',
        field: 'price',
        cellRenderer: ScreenerPriceCell,
        sortable: true,
        type: 'rightAligned'
      },
      {
        headerName: '% Change',
        field: 'percent_change',
        valueGetter: function (params) {
          return params.data.percent_change * 100;
        },
        cellRenderer: 'BaseCellPercentChange',
        sortable: true,
        type: 'rightAligned'
      }
    ],
    companies: [
      {
        headerName: 'Company',
        cellRenderer: TableRendererCompany,
        field: 'company'
      },
      {
        headerName: 'About',
        field: 'description',
        tooltipField: 'description',
        cellRenderer: highlightRenderer
      }
    ],
    recent_searches: [
      {
        headerName: 'Search',
        field: 'search'
      },
      {
        headerName: 'Actions',
        cellRenderer: RendererActions,
        cellRendererParams: {
          actions: ['delete']
        },
        type: 'rightAligned'
      }
    ],
    news: [
      {
        headerName: 'News',
        field: 'hit',
        cellRenderer: TableCellNewsItem
      }
    ],
    dashboards: [
      {
        headerName: 'Name',
        field: 'title',
        cellRenderer: highlightRenderer
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        cellRenderer: highlightRenderer
      }
    ],
    widget_templates: [
      {
        headerName: 'Name',
        field: 'title',
        cellRenderer: highlightRenderer
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        cellRenderer: highlightRenderer
      }
    ],
    datasets: [
      {
        headerName: 'Provider',
        field: 'provider',
        maxWidth: 150,
        cellRenderer: highlightRenderer
      },
      {
        headerName: 'Name',
        field: 'title',
        cellRenderer: highlightRenderer
      },
      {
        headerName: 'Description',
        field: 'description',
        tooltipField: 'description',
        cellRenderer: highlightRenderer
      }
    ]
  };

  const hitTransformMap = {
    coins: hit => {
      const coin = coins.value.find(c => c.coin_uid == hit.coin_uid);
      return {
        id: hit.id,
        coin: {
          uid: hit.coin_uid,
          ticker: hit.ticker,
          name: hit.name
        },
        tab: hit.tab.name,
        price: coin?.price || null,
        percent_change: coin?.price_return_24_hours || null
      };
    },
    companies: hit => {
      return {
        id: hit.id,
        company: {
          company_uid: hit.id,
          image_url: hit.image_url,
          name: hit.name
        },
        description: hit.description
      };
    },
    recent_searches: hit => {
      return {
        search: hit,
        id: hit
      };
    },
    news: hit => {
      return {
        hit: hit,
        id: hit.id
      };
    },
    dashboards: hit => hit,
    widget_templates: hit => hit,
    datasets: hit => hit,
    pages: hit => hit
  };

  const rowHeightMap = {
    news: 80
  };

  const columnDefs = columnDefsMap[collection];
  const hitsTransform = hits =>
    hits
      .filter((s, index, self) => collection != 'recent_searches' || self.indexOf(s) === index)
      .map(h => hitTransformMap[collection]?.(h))
      .flat();
  const rowHeight = rowHeightMap[collection];
  return { columnDefs, hitsTransform, rowHeight };
}
